.bdMultipleChoice {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/** {*/
/*    font-family: "Verdana", cursive, sans-serif;*/
/*    color: #ffffff;*/
/*}*/

/*.quest table{border: 1px solid #000 !important; font-family: "Courier New" !important;}*/
.jwb td{color: #00f;font-size:10px;padding:0 15px 0 0}

.bdMultipleChoice {
    background-color: #ffffff;
    display: contents;
/*//flex;*/
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    border-radius: 1vh;
}

.multipleChoice {
    background-color: #ffffff;
    /*width: 450px;*/
    /*min-width: 450px;*/
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
    width: 100%;
    display: contents;
            /*flex;*/
    flex-direction: column;
    justify-content: space-between;
}

.buttonMl {
    width: 100%;
    font-size: 16px;
    /*color: #ffffff;*/
    /*color: #ffffff;*/
    /*background-color: #252d4a;*/
    /*background-color: #252d4a;*/
    border-radius: 10px;
    display: flex;
    padding: 5px;
    margin: 0px;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #b8b8b8;
    cursor: pointer;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

.buttonMl:hover {
    background-color: #555e7d;
    color: white;
}

/*.buttonMl:active, .active {*/
/*    background-color: #555e7d;*/
/*    color: white;*/
/*}*/

.buttonMl:focus {
    outline: none;
}

.buttonMl svg {
    margin-right: 5px;
}


@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono');

//$easeIn: cubic-bezier(0.25, 0.46, 0.45, 0.94)
//$easeOut: cubic-bezier(0.55, 0.085, 0.68, 0.53)
$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$turnDuration: 0.6s;

// Animation mixin
@mixin animate($name, $easing, $duration: 300ms, $delay: 0s, $iteration: 1, $direction: normal, $fill-mode: forwards) {
  animation: $name $duration $easing $delay $iteration $direction $fill-mode; }

* {
  box-sizing: border-box; }

//body
//  margin: 0

//#app
//  display: flex
//  position: relative
//  width: 100%
//  min-height: 100vh
//  justify-content: center
//  align-items: center
//  background-color: #FBAB7E
//  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)

//header
//  display: flex
//  position: relative

////h1
////  font-family: 'Droid Sans Mono', monospace
////  font-weight: lighter
////  text-transform: uppercase
////  letter-spacing: 0.1em
////  color: white

$border: #bfbcbc;
$card: #403a3a;
$font-family: 'Droid Sans Mono', monospace;
//$font-size: 5em
$font-size: 2.3em;
$font-width: lighter;
$font-color: lighten(#dbcbcb, 20%);

.flipClock {
  display: flex;
  position: absolute;
  top: 52px;
  right: 17px;
  justify-content: space-between;
  //width: 3 * 140px + 80px
  width: 1.5 * 70px +40px; }

.flipUnitContainer {
  display: block;
  position: relative;
  //width: 140px
  width: 70px;
  //height: 120px
  height: 60px;
  perspective-origin: 50% 50%;
  //perspective: 300px
  perspective: 150px;
  background-color: $card;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px grey; }

.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid $border;

  span {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: $font-width;
    color: $font-color; } }

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid $border;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  span {
    transform: translateY(50%); } }

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid $border;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  span {
    transform: translateY(-50%); } }

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;

  span {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-width;
    color: $font-color; }

  &.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg); // from 180 to 0
    background-color: $card;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid $border;
    border-top: 0.5px solid $border;

    span {
      transform: translateY(-50%); } }

  &.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg); // from 0 to -180
    background-color: $card;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    span {
      transform: translateY(50%); } } }

.fold {
  @include animate(fold, $easeInOut, 0.6s);
  transform-style: preserve-3d; }

.unfold {
  @include animate(unfold, $easeInOut, 0.6s);
  transform-style: preserve-3d; }

@keyframes fold {
  0% {
    transform: rotateX(0deg); }
  100% {
    transform: rotateX(-180deg); } }

@keyframes unfold {
  0% {
    transform: rotateX(180deg); }
  100% {
    transform: rotateX(0deg); } }

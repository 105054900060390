@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";

@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/pages";

html body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $global;
  color: $gunmetal;
  font-weight: 400;
}

a {
  transition: all ease-in-out 0.3s;
  color: $greyblue;
  font-size: 13px;
  font-weight: 500;
}

img {
  max-width: 100%;

  &.loading {
    width: 100px;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 10px;
  outline: 1px solid $grey;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

h1 {
  font-size: 26px;
  font-weight: 700;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}

h2 {
  font-size: 14px;
  font-weight: 700;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

h3 {
  font-size: 12px;
  font-weight: 700;

  &.item-head {
    font-size: 14px;
  }
}

h4 {
  font-size: 10px;
}

p {
  font-size: 14px;
  font-weight: 600;
}

.text{
  &-alert {
    font-size: 14px;
    color: $red;
  }

  &-grey {
    color: $grey;
  }

  &-red {
    color: $reddish;
  }

  &-white {
    color: $white;
  }

  &-blue {
    color: $greyblue;
  }
}

.spin-absolute {
  position: absolute;
  left: -25px;
  top: 24px;
}

.card-label {
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 20px;


  &.locker {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    padding: 30px 20px;

    canvas {
      margin-right: 0;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  canvas {
    width: 75px;
    height: 75px;
    margin-right: 15px;
  }

  p {
    margin-bottom: 0;
    font-size: 10px;
  }
}

.alert-warning {
  color: $warm-grey;
  background: $very-light-pink;
  border-radius: 5px;
  padding: 10px;

  p {
    font-size: 10px;
  }
}

.steps {
  padding: 20px 15px;
  border-bottom: 1px solid $gunmetal;
  position: relative;

  @include media-breakpoint-up(md) {
    border-bottom: none;

    &:before {
      content: '';
      position: absolute;
      border-bottom: 1px solid $gunmetal;
      left: -10%;
      width: 20%;
      top: 45px;
    }

    &:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid $gunmetal;
      right: -10%;
      width: 20%;
      top: 45px;
    }
  }

  @include media-breakpoint-up(lg) {
    &:before {
      left: -5%;
    }

    &:after {
      right: -5%;
    }
  }
}

.card-cream {
  background: $white-grey;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-nota {
  border: solid 1px #d8d8d8;
  padding: 15px;
  border-radius: 10px;
}

.card-white {
  background: $white;
  border-radius: 5px;
  padding: 15px;

  @include media-breakpoint-up(md) {
    padding: 30px;
  }

  &__dash {
    background: $white;
    border-radius: 5px;
    padding: 15px;

    .title-grey {
      color: $brown-grey;
      margin-bottom: 5px;
      font-weight: 300;
    }

    .number {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .card-header {
      background: $dark-sky-grey;
      color: $white;
      border-radius: 5px;
      margin-bottom: 15px;
      border: none;

      &.grey {
        background: #f2f2f2;
        color: $gunmetal;
      }
    }

    .check-option {
      background: $white-grey;
      border: 1px solid $white-grey;
      color: $gunmetal;
      width: 100%;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      padding: 25px 15px;

      &.active {
        border: 1px solid $dark-sky-grey;
      }
    }
  }

  &__blue {
    background: $blue;
    color: $white;
    border-radius: 5px;
    padding: 15px;
  }
}

.card-sidenav {
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
  margin-right: 15px;
  margin-bottom: 15px;

  button.MuiButtonBase-root {
    font-size: 12px;

    @include media-breakpoint-up(md) {
      padding: 7px 15px;
    }
  }

  .card-header {
    background: transparent;
    color: $gunmetal;
    font-weight: 700;

    i {
      color: $greyblue;
      margin-right: 10px;
    }
  }

  .card-body {
    p {
      color: $warm-grey-two;
      font-size: 12px;
    }

    .number {
      font-size: 20px;
      font-weight: 700;
      color: $dark-sky-grey;
    }
  }
}

.card-info {
  background: $duck-egg-blue;
  color: $gunmetal;
  display: flex;
  padding: 15px;
  border-radius: 5px;

  i {
    color: $dark-sky-grey;
    font-size: 40px;
    margin-right: 15px;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.MuiTablePagination-root {
  .MuiIconButton-root {
    padding: 10px;
    border: none;
  }
}
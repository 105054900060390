.color-greyblue {
  color:$greyblue;
}
.color-black {
  color:$black;
}
.color-grey {
  color:$grey ;
}
.color-pinkish-grey {
  color:$pinkish-grey;
}
.color-white-grey {
  color:$white-grey;
}
.color-greyish {
  color:$greyish;
}
.color-white {
  color:$white;
}
.color-reddish {
  color:$reddish;
}
.color-french-blue {
  color:$french-blue;
}
.color-purplish-brown {
  color:$purplish-brown;
}
.color-dark-aquamarine {
  color:$dark-aquamarine;
}
.color-dark-sky-grey {
  color:$dark-sky-grey;
}
.color-blue-green {
  color:$blue-green;
}
.color-light-grey-green {
  color:$light-grey-green;
}
.color-dark-cream {
  color:$dark-cream;
}
.color-blush {
  color:$blush;
}
.color-white-two {
  color:$white-two;
}
.color-muted-pink {
  color:$muted-pink;
}
.color-gunmetal {
  color:$gunmetal;
}
.color-brown-grey  {
  color:$brown-grey ;
}
.color-faded-blue  {
  color:$faded-blue ;
}
.color-green {
  color :$green;
}
.color-leaf {
  color:$leaf;
}

.background-greyblue {
  background-color:$greyblue;
}
.background-black {
  background-color:$black;
}
.background-grey {
  background-color:$grey ;
}
.background-pinkish-grey {
  background-color:$pinkish-grey;
}
.background-white-grey {
  background-color:$white-grey;
}
.background-greyish {
  background-color:$greyish;
}
.background-color-white {
  background-color:$white;
}
.background-reddish {
  background-color:$reddish;
}
.background-french-blue {
  background-color:$french-blue;
}
.background-purplish-brown {
  background-color:$purplish-brown;
}
.background-dark-aquamarine {
  background-color:$dark-aquamarine;
}
.background-dark-sky-grey {
  background-color:$dark-sky-grey;
}
.background-blue-green {
  background-color:$blue-green;
}
.background-light-grey-green {
  background-color:$light-grey-green;
}
.background-dark-cream {
  background-color:$dark-cream;
}
.background-blush {
  background-color:$blush;
}
.background-white-two {
  background-color:$white-two;
}
.background-muted-pink {
  background-color:$muted-pink;
}
.background-gunmetal {
  background-color:$gunmetal;
}
.background-brown-grey  {
  background-color:$brown-grey ;
}
.background-faded-blue  {
  background-color:$faded-blue ;
}

.background-green  {
  background-color: $green;
}
.background-leaf {
  color:$leaf;
}

.p-8 {
  font-size: 8px;
}
.p-10 {
  font-size: 10px;
}
.p-12 {
  font-size: 12px;
}
.p-18 {
  font-size: 18px;
}

#root {
  position: relative;
}

//
.underline {
  border-bottom: 1px solid $pinkish-grey  ;
  max-width: 100%;
  margin: 0 auto;

  .reservation & {
    width: 80%;
  }
}

//button
.button-transparent {
  background: transparent;
  border: none;
  text-align: left;
}
.alert-heading  {
  padding: 10px 28px;
  border-radius: 10px;
  box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.04);
  display: inline-block;
  font-weight: 700;

  &.background-green {
    background-color: #81c784;
    text-align: left;
    width: 80%;
  }
}

header {
  &.button-language {
    background: $white-grey;
    position: absolute;
    z-index: 10;
    left: 15px;
    top: 15px;
    border-radius: 10px;
    padding: 0 7px 3px;

    @include media-breakpoint-up(md) {
      left: initial;
      right: 15px;
    }
    @include media-breakpoint-up(lg) {
      bottom: 5px;
      right: 21px;
      top: initial;
    }

    @include media-breakpoint-up(xl) {
      right: 15px;
    }

    button {
      background: transparent;
      border: none;
      color: $dark-sky-grey;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 8px;

      &:first-child {
        border-right: 1px solid $greyblue;
      }

      &.active {
        color: $black;
      }

      @include media-breakpoint-up(xl) {
        font-size: 14px;
      }
    }
  }

  &.header-nav {
    background: $dark-sky-grey;

    @include media-breakpoint-up(md) {
      min-height: 50px;
    }
  }
}

aside {
  &.side-nav {
    background: $dark-sky-grey;
    transition: 0.5s ease-in-out all;
    left: -250px;
    position: fixed;
    bottom: 0;
    top: 0;
    width: 250px;
    z-index: 1000;
    overflow-y: auto;
    flex-direction: column;
    padding: 15px 0 15px 15px;
    min-height: calc(100vh - 25px);

    @include media-breakpoint-up(md) {
      position: relative;
      left: 0;
      width: auto;
      padding-left: 0;
    }

    &.show {
      left: 0;

    }

    .header-logo {
      img {
        width: 100px;
      }
    }

    .nav {
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        margin-top: 40px;
      }

      .nav-item {
        padding: 5px 0 5px 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @include media-breakpoint-up(md) {
          padding: 10px 0 10px 15px;
        }

        &:hover,
        &.active {
          background: $pale-grey;

          a,
          button {
            color: $dark-sky-grey;
            text-decoration: none;
          }
        }

        a,
        button {
          color: $white;
          background: transparent;
          border: none;
          font-size: 15px;
          padding: 0;

          i {
            width: 25px;
          }
        }
      }
    }
  }
}

main {
  &.login {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url("../images/login-mobile.png");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 576px;
      width: 100%;
      height: 100%;
      top: -60px;

      @include media-breakpoint-up(sm) {
        background-size: 767px;
        height: 450px;
        top: -85px;
      }

      @include media-breakpoint-up(md) {
        background-image: url("../images/login.png");
        background-position: center right;
        background-size: cover;
        height: 100%;
        width: 50%;
        top: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 57%;
      }
    }

    &::after {
      @include media-breakpoint-up(md) {
        content: '';
        position: absolute;
        width: 20px;
        right: 0;
        top: 0;
        z-index: 100;
        background: $dark-sky-grey;
        height: 100%;
      }
    }

    @keyframes pulse-red {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 30px rgba(255, 82, 82, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
      }
    }

    .img-logo {
      // border : 1px solid rgba(255, 0, 0, 1);
      // animation: pulse-red 4s infinite;
      //width: 120px;
      //width: 300px;
      //border-radius: 99999px;
     /* margin-bottom: 55px;*/

      @include media-breakpoint-up(sm) {
        margin-top: 50px;
/*        margin-bottom: 50px;*/
      }

      @include media-breakpoint-up(md) {
        width: 100px;
        margin: auto;
        padding: 0;
      }

      @media (min-width: 1600px) {
        width: 140px;
        margin: auto;
        position: relative;
       /* left: -45%;*/
      }
    }
    .img-logo-footer {

      width: 60px;
      @include media-breakpoint-up(sm) {
        margin-top: 25px;
      }

      @include media-breakpoint-up(md) {
        width: 50px;
        margin: auto;
        padding: 0;
      }

      @media (min-width: 1600px) {
        width: 70px;
        margin: auto;
        position: relative;
      }
    }

    h1 {
      font-size: 26px;

      @include media-breakpoint-up(xl) {
        margin-left: -50px;
        margin-right: -50px;
      }

      &.title {
        font-size: 60px;
        font-weight: bolder;
        color: $pale-grey;
      }
      &.sub-title {
        font-weight: bolder;
        color: $pale-grey;
      }
    }

    section {
      min-height: 95vh;

      form {
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .row-login {
      @include media-breakpoint-up(md) {
        min-height: 100vh;
      }
    }

    .footer-float {
      @include media-breakpoint-up(md) {
        position: relative;
        bottom: 0;
        width: 100%;
        left: 0;
      }
    }
  }

  &.success {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &.dashboard {
    min-height: calc(100vh - 50px);
    background: $dark-sky-grey;

    .section-dashboard {
      background: $pale-grey;
      border-radius: 5px;
      padding: 15px;
      min-height: calc(100vh - 85px);

      @include media-breakpoint-up(md) {
        padding: 15px 30px;
      }

      .heading {
        background: rgba(65, 154, 210, 0.11);
        padding: 10px;
        margin-bottom: 15px;

        p {
          font-size: 12px;
          color: $gunmetal;
          margin-bottom: 0;
        }
      }
    }
  }
}

footer {
  p {
    font-size: 12px;
  }

  &.dashboard-footer {
    background: $dark-sky-grey;
    color: $white;
    padding: 5px 15px;
    font-size: 10px;
  }
}

.breadcrumb {
  background: transparent;
  padding: 10px 0;
  border-bottom: 1px solid $grey;
  border-radius: 0;

  .breadcrumb-item {
    font-size: 10px;

    a {
      font-weight: 400;
      font-size: 10px;
    }

    &.active {
      font-weight: 700;
    }
  }
}

.text-big {
  font-size: 21px;
  font-weight: 900;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}
.img-logo {
  padding: 50px 0;
  @include media-breakpoint-up(md) {
    padding: 100px 0;
    padding-bottom: 70px;
  }
}
.small-text {
  height: 13px;
  font-size: 10px;
}
.button-small {
  .MuiButton-root {
    font-size: 12px;
    font-weight: bold;
    width: 126px;
  }
}
.absolute-bottom {
  margin-top: 30px;
  bottom: 0;
  width: 100%;
}
.form-control.tab {
  font-size: 14px;
  padding: 5px;
  width: 70px;
  display: inline-block;
  margin-right: 3px;
  height: 30px;
}
/*.form-group {
  max-width: 580px;
  margin: 0 auto;
}*/
.date {
  max-width: 300px;
  margin-left: 75px;
}

//reservation
.reservation {
  padding-bottom: 30px;
  min-height: 80vh;

  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }

  .MuiSelect-select{
    font-size: 12px;
    font-weight: 600;
    color: $gunmetal;
  }

  .MuiFormLabel-root {
    font-size: 12px;
  }
}

.table-head {
  max-width: 620px;
  margin: 0 auto;
  background: $ice-blue;
  padding: 10px 0;
}

.table-body {
  max-width: 620px;
  margin: 0 auto;
  align-items: center;

  .btn-block {
    padding: 0 5px;
    font-size: 12px;
    color: $brown-grey;
    border-color: $brown-grey;
    font-weight: 500;
  }
}

.MuiDialog-paper {
  .MuiDialogTitle-root {
    padding: 15px;

    @include media-breakpoint-up(md) {
      padding: 25px 15px 10px;
    }

    h2 {
      font-family: $global;
      text-align: center;
      font-weight: 700;
    }
  }

  .MuiDialogContent-root {
    form {
      max-width: 400px;
      margin: auto;

      label {
        font-size: 11px;
        margin: 0;
        color: $greyish;
      }
    }
  }

  .MuiDialogActions-root {
    width: 400px;
    margin: auto;
    justify-content: space-between;
    max-width: 100%;
    padding-bottom: 15px;
  }
}


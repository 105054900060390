$greyblue         : #62aed6;
$black            : #000000;
$grey             : #afafaf;
$pinkish-grey     : #c6c6c6;
$white-grey       : #f8f8f8;
$greyish          : #b8b8b8;
$warm-grey        : #797979;
$warm-grey-two    : #969696;
$pale-grey        : #f1f5f8;
$white            : #FFFFFF;
$reddish          : #c73a3a;
$french-blue      : #4267b2;
$blue      : #005f9b;
$purplish-brown   : #3e3039;
$dark-aquamarine  : #00796b;
$dark-sky-blue    : #419ad2;
$dark-sky-grey    : #797979;
$blue-green       : #0f9a87;
$light-grey-green : #c8e6c9;
$dark-cream       : #fff59d;
$blush            : #ef9a9a;
$very-light-pink  : #fde7e7;
$white-two        : #f5f5f5;
$muted-pink       : #ce7499;
$gunmetal         : #56585a;
$brown-grey       : #888888;
$faded-blue       : #818cc7;
$green            : #81c784;
$ice-blue         : #eaf7ff;
$duck-egg-blue    : #dceefa;
$leaf             : #69b938;


//Fonts
$global    : 'Quicksand', sans-serif;

button,
a{
  &:focus {
    outline: none;
  }

  &.full {
    width: 100%;
  }

  &.btn-table {
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #eaeaea;
    margin-right: 3px;
    color: $black;
    font-weight: 700;
  }

  &.MuiButtonBase-root {
    font-weight: 700;
    padding: 10px;
    font-family: $global;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      padding: 10px 25px;
    }

    &.pass {
      padding: 0;

      i {
        font-size: 14px;
      }
    }

    &.MuiPickersDay-day {
      padding: 10px;
    }
  }

  &.MuiButton-containedPrimary {
    background-color: $greyblue;
    border: 1px solid $greyblue;

    &:hover {
      background-color: darken($greyblue, 10%);
    }
  }

  &.MuiButton-containedSecondary {
    background-color: $white;
    border: 1px solid $greyblue;
    color: $greyblue;

    &:hover {
      background-color: darken($white, 10%);
    }
  }

  &.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.btn-link {
    background-color: transparent;
    color: $greyblue;
    border: none;
    box-shadow: none;
  }

  &.float-btn {
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }


  &.btn-icon {
    border: none;
    background: transparent;
    color: $dark-sky-blue;
  }

  ///////////////////////////////
  &.btn-icon {
    border: none;
    background: transparent;
    color: $dark-sky-grey;
  }
}

form {
  .info {
    font-weight: 600;
    font-size: 12px;
  }

  .form-group {
    .MuiInputBase-root,
    .MuiInputLabel-formControl {
      font-family: $global;
      font-weight: 600;
    }

    .MuiFormLabel-root {
      font-family: $global;
    }

    .MuiFormControlLabel-label {
      font-size: 12px;
      color: $gunmetal;
      text-align: left;
    }

    .MuiCheckbox-colorPrimary {
      color: $greyblue;
    }

    .MuiInputLabel-formControl {
      font-size: 14px;
    }

    #upload-image {
      position: absolute;
      right: 10px;
      top: 15px;
      font-weight: 700;
      color: $dark-sky-blue;
    }

    ///////////////////////////
    #upload-image {
      position: absolute;
      right: 10px;
      top: 15px;
      font-weight: 700;
      color: $dark-sky-grey;
    }

    .tooltip-icon {
      color: $warm-grey;
      position: absolute;
      font-size: 10px;
      right: -15px;
      top: 25px;

      &:hover + .tooltip-image {
        opacity: 1;
      }
    }

    .tooltip-image {
      position: absolute;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      padding: 10px;
      z-index: 3;
      top: -70px;
      right: -45px;
      opacity: 0;
      transition: all ease-in-out 0.1s;

      @include media-breakpoint-up(md) {
        top: -65px;
      }
    }
  }

  .MuiButton-root {
    font-family: $global;
  }
}

.MuiFormControl-root {
  legend {
    font-family: $global;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
    font-family: $global;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    background: $white;
    padding: 10px;
    font-family: $global;
  }
}


.MuiListItem-root {
  &.menu-btn {
    color: $greyblue;
    font-size: 14px;

    i {
      margin-right: 5px;
    }
  }
}

.full {
  width: 100%;
}

.Toastify__toast {
  font-family: $global;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;

  &.Toastify__toast--error {
    background: $reddish;
  }

  &.Toastify__toast--success {
    background: $blue-green;
  }
}

.text-danger {
  color: $reddish;
  font-size: 12px;
  text-align: left;
}

.table-list {
  .MuiTableCell-root {
    font-family: $global;
  }

  .MuiTableCell-head {
    color: $gunmetal;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    border: none;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      border-bottom: 5px solid $pale-grey;

      .MuiTableCell-root {
        border: none;
        background: $white;
        font-size: 14px;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}